
*{text-decoration:none !important; outline:0 !important; list-style:none !important;margin: 0;
  padding: 0;box-sizing: border-box;}
body {
  color:#2b2a2a; 
  background:#ffffff; 
  font-family: 'proximanova-semibold';
  background-color: #fff;
}
body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden; 
}
h1,h2,h3,h4,h5,h6{font-family: 'proximanova-bold';}
a,a:hover {transition:0.6s all !important;}

/* .form-control:focus {box-shadow:0px 0px 5px #fed307 !important; border-color:#fed307;} */ .form-control:focus {
  box-shadow: 0px 0px 5px #9b93fd !important;
}
*, :before, :after{
  --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235/var(--tw-border-opacity));
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
}

.react-mapbox-ac-input {
    margin-bottom: 0rem !important;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.otf'),
        url('../fonts/ProximaNova-Regular.otf');
      font-weight: normal;
      font-style: normal;
}
@font-face {
  font-family: 'proximanova-semibold';
  src: url('../fonts/proximanova-semibold.otf'),
        url('../fonts/proximanova-semibold.otf');
      font-weight: normal;
      font-style: normal;
}

@font-face {
  font-family: 'proximanova-bold';
  src: url('../fonts/proximanova-bold.otf'),
        url('../fonts/proximanova-bold.otf');
      font-weight: normal;
      font-style: normal;
}

.mp{
    margin-top: 10px;
}
.account_profileset{
    text-align: center;
}
.account_profileset a{
    display: inline-block;
    padding: 10px 38px;
    border-radius: 40px;
    color: #fff;
    font-size: 19px;
    background-color: #00824f;
    font-family: 'proximanova-bold';
}
.account_profileset h3{
    color: #000;
    font-size: 31px;
    margin-bottom: 20px;
}
.account_profileset img{
    width: 110px;
    margin-bottom: 21px;
    border-radius: 100%;
}
.account_profileset p{
    color: #000;
    font-size: 17px;
    margin-bottom: 31px;
}
header{    
    position: inherit;
    top: 0;
    right: 0;
    z-index: 111;
    width: 100%;
    border-bottom: 1px solid #F1F1F1;
    padding-left: 252px;
}
#root{
    display: inline-block;
    width: 100%;
    height: 100%;
}
.contentCoverbox {
    min-height: 100%;
    background: #F1F1F1;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    float: left;
    position: relative;
    padding-bottom: 100px;
    /* height: 100%; */
}

.marketplace_search .nofounfimg{
    position: inherit;
    width: auto;
    height: auto;
}
.sidebarmenu {
    min-height: 100%;
    float: left;
    width: 250px;
    border-right: 0px solid #D2D2D2;
    background: #00824F;
    z-index: 111;
    position: fixed;
    display: inline-block;
    height: auto;
    left: 0;
    top: 0px;
    overflow: auto;
    height: 100vh;
}
.sidebarmenu::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .sidebarmenu::-webkit-scrollbar-track {
    background: #fff; 
  }
   
  /* Handle */
  .sidebarmenu::-webkit-scrollbar-thumb {
    background: #b2b2b2; 
  }

  .custloading{
    opacity: 0.5;
    /* pointer-events: none;
    cursor: not-allowed; */
  }
  .custloading .colSetContent {   
    cursor: no-drop;
}
.marketplace_search .colSetContent img{
    position: inherit;
    width: 27px;
    margin-top: 11px;
}

.viewbtn{
    background: #00824F;
    font-size: 15px;
    color: #fff;
    padding: 4px 12px;
    border-radius: 20px;
    display: inline-block;
    line-height: 21px;
    margin-top: 10px;
    width: 100%;
    text-align: center

}
.availablebalance_text{
    display: inline-block;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    margin-bottom: 10px;
}
.availablebalance_text p{
    font-size: 20px;
    color: #fff;
    margin: 0;
    margin-bottom: 12px;
    font-family: 'ProximaNova-Regular';
}
.availablebalance_text h3{
    font-size: 37px;
    color: #fff;
    margin: 0;
    font-family: 'ProximaNova-Regular';
}
.ask_btndiv{
    display: inline-block;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    bottom: 0px;
    left: 0;
    padding-bottom: 20px;
    padding-top: 20px;
}
.ask_btndiv a{
    color: #fff;
    border: 1px solid #fff;
    font-size: 18px;
    padding: 11px 40px;
    display: inline-block;
    border-radius: 40px;
    width: 100%;
    text-align: center;
}
.menuHeading{
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #fff;
    color: #CCFEEF;
    font-size: 17px;
    margin-bottom: 5px;
    padding-bottom: 4px;
}
.sidebarmenu ul {
    width: 100%;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 22px;
}
.sidebarmenu ul li {
    display: inline-block;
    width: 100%;
}
.sidebarmenu ul li a {
    display: inline-block;
    color: #80C1A7;    
    font-size: 18px;
    width: 100%;
    padding: 8px 0px 8px;
    border-radius: 6px;
    font-family: 'ProximaNova-Regular';
}
.top_logodiv{
    display: inline-block;
    margin-right: 49px;
    float: left;
}
.top_logodiv img{
    width: 250px;
}
.sidebarmenu ul li.active a {
/*    font-family: 'proximanova-bold';*/
    color: #fff;
}
.menumarginset{
    margin-top: 7px;
}
.account_textbox{       
    display: inline-block;
    width: 100%;    
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 7px;
}
.account_textbox ul{
    padding: 0;
    border-top: 0px solid #80C1A7;
    padding-top: 0px;
}
.account_textbox li{}

.rightsidecontent {
    position: relative;
    padding: 0;
    width: calc(100% - 0px);
    float: right;
    padding-left: 292px;
    padding-right: 25px;
    padding-top: 32px;
    display: inline-block;
    margin-top: 0;
}
.top_headerdiv{
    display: inline-block;
    width: 100%;
    background-color: #fff;        
    padding: 13px 20px; 
    float: left; 
    border-bottom: 1px solid #cbcbcb;
}

.link_a_btn {
    display: inline-block;
    background-color: #00824F;
    padding: 10px 0px;
    border-radius: 70px;
    color: #fff;
    font-size: 15px;
    width: 90%;
    text-align: center;
    position: absolute;
    border: 0;
    bottom: 18px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.header_lefttext{
    float: left;
    width: 54%;
    line-height: normal;
}
.header_lefttext ul{
    padding: 0px;
    margin: 0px;
    display: inline-block;
    width: 100%;
    padding-top: 14px;
}
.header_lefttext li{
    float: left;
    width: 25%;
}
.header_lefttext li h3{
    margin: 0px;
    color: #000;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header_lefttext li p{
    margin: 0px;
    font-family: 18px;
    color: #000;
}
.header_lefttext li p span{
    font-family: 'proximanova-bold';
}
    
.header_righttext{
    float: right;
    width: 194px;
    line-height: normal;
    margin-top: 14px;
}
.header_balancetext{
    display: inline-block;
    width: 100%;
    text-align: left;
    padding-right: 0;
}
.header_balancetext p{
    margin: 0px;
    color: #000;
    font-size: 18px;
    display: inline-block;
}
.header_balancetext h3{
    margin: 0px;
    color: #000;
    font-size: 20px;
}
.header_balancetext .show_balance_btn{
    padding: 0;
    background: transparent;
    color: #000;
    font-size: 18px;
    margin: 0;
    border-radius: 0;
    float: none;
}
.header_balancetext a{
    display: inline-block;
    /*background: #DE2323;*/
    background: #00824F;
    font-size: 15px;
    color: #fff;
    padding: 11px 19px;
    border-radius: 20px;
    float: right;
    margin-top: -11px;
}
.marketplace_search{
    display: inline-block;
    width: 100%;    
}
.marketplace_search .input-group input{
    border-radius: 0 !important;
    border: 2px solid #FFF !important;
    font-size: 16px;
    color: #000;
    width: 100%;
    padding-left: 43px;
    box-shadow: 0 0 5px #c4c4c4;
  /*  box-shadow: 5px 7px 0px rgb(217 217 217);*/
    height: 54px;
}
.marketplace_search .form-control:focus {
    box-shadow: 0 0 5px #c4c4c4 !important; 
    /*box-shadow: 5px 7px 2px rgb(217 217 217) !important;    */
}
.marketplace_search img{
    position: absolute;
    top: 17px;
    left: 16px;
    z-index: 11;
    width: 22px;
}

.marketplace_boxdiv{
    display: inline-block;
    width: 100%;
    margin-top: 40px;
}
/*.marketplace_boxdiv .col-lg-3{
    width: 20%;
}*/
.match_boxmandiv{
    position: relative;
    display: inline-block;
    width: 100%;    
    box-shadow: 0 0 5px #c4c4c4;
    /*box-shadow: 5px 7px 0px rgb(217 217 217);*/
    border: 2px solid #FFF;
    padding: 20px 10px;
    margin-bottom: 50px;
    min-height: 650px;
    background: #fff;
}
.match_boxmandiv h3{
    margin: 0px;
    font-size: 17px;
    color: #000;
    margin-bottom: 10px;
}

.match_boxmandiv span{
    margin: 0px;
    color: #000;
    font-size: 14px;
    margin-bottom: 6px;
    /*display: block;*/
    position: relative;
    margin-right: 6px;
}

.link_a_btn span{
    margin: 0px;
    color: #fff;
    font-size: 14px;
    margin-bottom: 0px;
    /*display: block;*/
    position: relative;
    margin-right: 6px;
}
.sendleadbtn span{
    margin: 0px;
    color: #00824F;
    font-size: 14px;
    margin-bottom: 0px;
    /*display: block;*/
    position: relative;
    margin-right: 6px;
}
/*.inner_matchtext span:after{
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: #787878;
    border-radius: 100%;
    left: 0;
    top: 7px;
}*/
.match_boxmandiv .viewProfilebtn a{
    bottom: 78px;
}
.match_boxmandiv a{
    margin: 0 auto;
    display: inline-block;
    background-color: #00824F;
    padding: 10px 0px;
    border-radius: 70px;
    color: #fff;    
    font-size: 15px;
    width: 90%;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 87px;
    cursor: pointer;
    font-family: 'ProximaNova-Regular';
}
.inner_matchtext{
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
}
.inner_matchtext p{
    margin: 0px;
    color: #787878;
    font-size: 14px;
    position: relative;
    /*padding-left: 12px;*/
}
/*.inner_matchtext p:after{
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: #787878;
    border-radius: 100%;
    left: 0;
    top: 7px;
}*/

.custometable {
    min-width:  650px;    
    border-spacing: '0px 4px';
}
.customers_boxdiv{
    display: inline-block;
    width: 100%;
    border: 2px solid #FFF;
    /*box-shadow: 5px 7px 0px rgb(217 217 217);*/
    box-shadow: 0 0 5px #c4c4c4;
    padding: 15px 15px;    
    background: #fff;
}
.timeleft_text.vehiclelead_timeleft{
    position: inherit;
    padding-top: 14px;
    margin-top: 15px;
    border-top: 2px solid #ddd;
}
.timeleft_text{
    display: inline-block;
    width: 100%;
    border-top: 1px solid #ddd;
    position: absolute;
    bottom: 0px;
    text-align: center;
    z-index: 1;
    left: 0;
}
.timeleft_text p{
    margin: 0;
    font-size: 17px;
    color: #000;
    padding-top: 11px;
    padding-bottom: 11px;
}
.timeleft_text p span{
    font-size: 29px;
    margin-bottom: 0;
    margin-right: 0;
    position: relative;
    top: 4px;
}
.leftcustomers_detils{
    display: inline-block;
    width: 100%;
}
.leftcustomers_detils h3 {
    margin: 0px;
    font-size: 17px;
    color: #000;
    margin-bottom: 10px;
}
.leftcustomers_detils span {
    margin: 0px;
    color: #000;
    font-size: 14px;
    margin-bottom: 6px;
    /* display: block; */
    margin-right: 6px;
}
.notescustomer_boxdiv{
    display: block;
    width: 100%;
    border: 0px solid #C9C1C1;
    padding: 0px 0px;
    margin-top: 30px;
    /*height: 335px;*/
}
.notescustomer_boxdiv h3{
    margin: 0px;
    color: #000;
    font-size: 27px;
    margin-bottom: 10px;
}
.notescustomer_boxdiv p{
    margin: 0px;
    color: #787878;
    font-size: 14px;
}
.notescustomer_boxdiv textarea{
    /*border: 0px !important;
    padding: 0px !important; 
    color: #787878 !important;   */
}
.customer_btndiv{
    display: inline-block;
    width: 100%;
    text-align: right;
    margin-bottom: 30px;
}
.customer_btndiv a{
    display: inline-block;
    background: transparent;
    color: #00824F;
    padding: 8px 30px;
    border-radius: 5px;
    font-size: 15px;
    line-height: 23px;
    margin-left: 8px;
    border: 1px solid #00824F;
}
.customer_btndiv .active{
    background: #00824F !important;
    color: #fff !important;
}

.customer_btndiv .checkcontainer {      
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #252526;
    float: right;
    margin-left: 28px;
    margin-top: 5px;
}

/* Hide the browser's default checkbox */
.customer_btndiv .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.customer_btndiv .checkmark {
    position: absolute;
    top: -4px;
    left: -3px;
    height: 33px;
    width: 33px;
    background-color: #DEDEDE;
    border-radius: 7px;
}


/* When the checkbox is checked, add a blue background */
.customer_btndiv .checkcontainer input:checked ~ .checkmark {
  background-color: #00824F;
}

/* Create the checkmark/indicator (hidden when not checked) */
.customer_btndiv .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customer_btndiv .checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customer_btndiv .checkcontainer .checkmark:after {
    left: 13px;
    top: 6px;
    width: 8px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.subject_input{
    display: inline-block;
    width: 100%;
    margin-top: 30px;
}
.subject_input input{
    width: 100%;
    color: #000;
    font-size: 15px;
    border: 1px solid #C9C1C1;
    height: 45px;
    padding: 10px 10px;
    margin-bottom: 16px;
}
.note_div textarea{
    width: 100%;
    color: #000;
    font-size: 15px;
    border: 1px solid #C9C1C1;
    height: 140px;
    padding: 10px 10px;
    resize: none;
}
.subject_input textarea{
    width: 100%;
    color: #000;
    font-size: 15px;
    border: 1px solid #C9C1C1;
    height: 430px;
    padding: 10px 10px;
    resize: none;
}


.sendmessage textarea {
    width: 100%;
    color: #000;
    font-size: 15px;
    border: 1px solid #C9C1C1;
    height: 300px;
    padding: 10px 10px;
    resize: none;
}

.subject_input ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #787878;
}
.subject_input ::-moz-placeholder { /* Firefox 19+ */
  color: #787878;
}
.subject_input :-ms-input-placeholder { /* IE 10+ */
  color: #787878;
}
.subject_input :-moz-placeholder { /* Firefox 18- */
  color: #787878;
}
.setfooter_border{
    border-top: 1px solid #ABABAB;
}
.footer_logodiv{
    display: inline-block;
    width: 100%;
    text-align: center;
    padding:12px;
    background: #fff;
    margin-top: 160px;
    position: absolute;
    bottom: 0;
}
.footer_logodiv img{
    width: 160px; 
}  
.newfootlogo{    
    position: absolute;
    right: 12px;
    top: 25px;
    width: 220px !important;
} 
.logfirst{      
    width: 220px !important;
} 
.vertical_logobox{
    float: left;
    width: 259px;    
    background-color: #00824F;
    text-align: center;
    display: flex;
    justify-content: center;
    position: fixed;
    height: 100%;
    top: 0;
}

.hidden1{
    display: none;
}

.vertical_logobox img{
    width: 251px;
}
.rught_sidebox{
    position: relative;
    padding: 0;
    width: calc(100% - 0px);
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*padding-left: 292px;
    padding-right: 25px;
    padding-top: 30px;
    display: inline-block;
    margin-top: 0;*/
}
.create_detailsbox{
    background-color: #fff;
    width: 544px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 60px;
    box-shadow: 0px 0px 5px rgb(196 196 196);
}
.create_innerboxdiv{
    display: inline-block;
    width: 100%;
    padding: 25px 21px;
}
.create_innerboxdiv h2{
    margin: 0px;
    color: #00824F;
    font-size: 22px;
    text-align: center;
    margin-bottom: 12px;
}
.account_inputbox{
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}
.account_inputbox p{
    color: #636161;
    margin: 0px;
    font-size: 15px;
    margin-bottom: 6px;
}
.account_inputbox input{
    width: 100%;
    border: 2px solid #E1E1E1;
    height: 50px;
    padding: 0px 12px;
    font-size: 15px;
    color: #000;
}
.account_inputbox input:focus{
    border-color: #000;
}
.account_inputbox textarea:focus{
    border-color: #000;
}
.account_inputbox input.shimmerEffect{
    font-size: 0px;
}
.account_inputbox .profileimage{
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
}
.custam_checkbox{
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.custam_checkbox .checkcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #636161;
}

/* Hide the browser's default checkbox */
.custam_checkbox .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custam_checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: #DEDEDE;
    border-radius: 5px;
}
.custam_checkbox .checkcontainer input:checked ~ .checkmark {
  background-color: #00824F;
}
.custam_checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custam_checkbox .checkcontainer input:checked ~ .checkmark:after {
  display: block;
}
.custam_checkbox .checkcontainer .checkmark:after {
    left: 11px;
    top: 5px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.custam_checkbox .checkcontainer a{
    color: #00824F;
    text-decoration: underline !important;
}
.createaccount_btn{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 19px;
}
.createaccount_btn a{
    display: inline-block;
    background-color: #00824F;
    width: 100%;
    color: #fff !important;
    font-size: 17px;
    border-radius: 9px;
    padding: 11px 0px;
    cursor: pointer;
}
.createaccount_btn button[type=button]{
    display: inline-block;
    background-color: #b6b9b8;
    width: 100%;
    color: #fff;
    font-size: 17px;
    border-radius: 9px;
    padding: 11px 0px;
    border: 0;
}
.createaccount_btn button[type=submit]{
    display: inline-block;
    background-color: #00824F;
    width: 100%;
    color: #fff;
    font-size: 17px;
    border-radius: 9px;
    padding: 11px 0px;
    border: 0;
}
.alreadyaccount_boxset{
    display: inline-block;
    width: 100%;
    text-align: center;
    border-top: 7px solid #DEDEDE;
    padding: 20px 0px;
    margin-top: 20px;
}
.alreadyaccount_boxset p{
    margin: 0px;
    font-size: 16px;
    color: #636161;
}
.alreadyaccount_boxset p a{
    color: #00824F;
    font-family: 'proximanova-bold';
    text-decoration: underline !important;
}
.accountbalance_textbox{
    margin: 0px;
    color: #636161;
    font-size: 15px;
    text-align: center;
/*    margin-top: -25px;*/
    margin-bottom: 25px;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 25px;
}
.balance_textbox{
    display: inline-block;
    width: 100%;
    margin-bottom: 28px;
}
.balance_textbox ul{
    padding: 0px;
    margin: 0px;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 28px;
}
.balance_textbox ul li{
    float: left;
    width: 33.33%;
    cursor: pointer;
}
.balance_textbox ul li a{
    display: inline-block;
    border: 2px solid #E1E1E1;
    font-size: 18px;
    color: #000;
    padding: 10px 0px;
    width: 89%;
}
.balance_textbox ul .active a{
    border-color: #00824F;
}
.balance_textbox h3{
    color: #252526;
    font-size: 15px;
    margin: 0px;
    margin-bottom: 4px;
}
.balance_textbox p{
    margin: 0px;
    color: #636161;
    font-size: 15px;
}
.account_categorybox{
    display: inline-block;
    width: 100%;
}
.account_categorybox ul{
    padding: 0px;
    margin: 0px;
    display: inline-block;
    width: 100%;
}
.account_categorybox ul li{
    display: inline-block;
    margin-right: 8px;    
}
.account_categorybox ul li a{
    display: inline-block;
    background-color: #fff;
    border: 2px solid #70af97c9;
    padding: 7px 4px;
    color: #000;
    font-size: 14px;
    opacity: 0.5;
    border-radius: 5px;
}
.account_categorybox ul .active a{        
    color: #000;
    border: 3px solid #00824F;
     opacity: 1;
}
.myaccount_mandiv{
    float: left;
}
.myaccount_mandiv .create_detailsbox{
    margin-bottom: 0px;
    margin-top: 35px;
}

.transactionhistory_mandiv{
    display: inline-block;
    width: 815px;
    box-shadow: 0px 0px 5px rgb(196 196 196);
    padding: 25px 21px;
    background-color: #fff;
    margin-top: 35px;
}


.userlist_mandiv{
    display: inline-block;
    width: 1000px;
    box-shadow: 0px 0px 5px rgb(196 196 196);
    padding: 25px 21px;
    background-color: #fff;
    margin-top: 35px;
}

.history_titlebox{
    display: inline-block;
    width: 100%;    
    text-align: center;
}
.history_titlebox h3{
    margin: 0px;
    color: #00824F;
    font-size: 22px;
    margin-bottom: 12px;
}
.history_titlebox p{
    margin: 0px;
    color: #636161;
    font-size: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 25px;
}
.connect_titlediv{
    display: inline-block;
    width: 100%;
}
.connect_titlediv h2{
    text-align: left;
    color: #000;
    margin-bottom: 1px;
    font-size: 23px;
}
.connect_titlediv span{
    color: #000;
    font-size: 15px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}
.sendleadbtn{
    border: 2px solid #01814e;
    background-color: transparent;
    float: right;
    padding: 7px 20px;
    border-radius: 4px;
    font-size: 15px;
    margin-top: 15px;
}
.history_detilsbox{
    display: inline-block;
    width: 100%;
}
.history_detilsbox .crmlisting{
    border: 0;
    padding-bottom: 4px;
}
.history_detilsbox .crmlisting li {   
    width: 100%;
}
.history_detilsbox .crmlisting li p{
    font-size: 17px;
}
.history_detilsbox .crmlisting li span{
    float: right;
}
.history_detilsbox .crmlisting li button {
    border: 0px;
}
.history_detilsbox .crmlisting li button img{
    width: 16px;
}
.history_detilsbox h3{
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 18px;
    border-top: 2px solid #b6b6b6;
    padding-top: 30px;
}
.history_detilsbox ul{
    padding: 0px;
    margin: 0px;
    display: inline-block;
    width: 100%;
    border-bottom: 2px solid #DEDEDE;
    margin-bottom: 4px;
    padding-bottom: 13px;
}
.history_detilsbox ul li{
    float: left;
    width: 130px;
}
.history_detilsbox ul li p{
    color: #252526;
    font-size: 14px;
    margin: 0px;
    font-family: 'proximanova-bold';
}
.history_detilsbox ul li span{
    color: #636161;
    font-size: 15px;
}
.deposit_color{
    color: #00824F !important;
}
.setaccount_textbox{
    border-bottom: 0px solid #DEDEDE;
    padding-bottom: 16px;
}
.email_inputbox{
    width: 420px;
    display: block;
}
.email_inputbox a{
    display: inline-block;
    background-color: #F1F1F1;
    padding: 12px 20px;
    color: #787878;
    font-size: 16px;
}
.email_inputbox .account_inputbox{
    margin-bottom: 20px;
}
.email_inputbox .account_inputbox p{
    color: #252526;
    font-family: 'proximanova-bold';
}
.password_textbox{
    display: inline-block;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
}
.password_textbox h3{
    color: #252526;
    font-size: 15px;
    margin: 0px;
}
.password_textbox p{
    margin: 0px;
    font-size: 15px;
    color: #636161;
    margin-bottom: 12px;
}
.password_textbox a{
    display: inline-block;
    background-color: #fff;
    padding: 10px 25px;
    color: #00824F;
    font-size: 16px;
    border: 2px solid #E1E1E1;
}
.setfooter_addclass{
    display: inherit;
    background: #fff;
}
.newsetfooter_addclass{
    display: inherit;    
}

.Payment_checkboxdiv{
    display: inline-block;
    width: 100%;
    border: 1px solid #E6E6E6;
    padding: 22px 15px;
}
.Payment_checkboxdiv p{
    margin: 0px;
    color: #3C4257;
    font-size: 16px;
    font-size: 18px;
    padding-bottom: 10px;
}
.Payment_checkboxdiv img{
    float: left;
    position: absolute;
    left: 7px;
    top: 3px;
}
.Payment_checkboxdiv .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #9F9F9F;
    font-weight: normal;
}

/* Hide the browser's default checkbox */
.Payment_checkboxdiv .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.Payment_checkboxdiv a{
    color: #E6E6E6;
    font-size: 18px;
    border-bottom: 1px solid #E6E6E6;
    margin-top: 20px;
    display: inline-block;
}

/* Create a custom checkbox */
.Payment_checkboxdiv .checkmark {
    position: absolute;
    top: -4px;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid #BEBEBE;
}


/* When the checkbox is checked, add a blue background */
.Payment_checkboxdiv .container input:checked ~ .checkmark {
  background-color: #009C67;
  border: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Payment_checkboxdiv .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* PAYMENT MODEL */
.addpayment_modalmandiv{

}
.addpayment_modalmandiv .modal-content {
    border-radius: 0px;
}
.addpayment_modalmandiv .modal-header h4{
    color: #000;
    font-size: 19px;
}
.addpayment_modalmandiv .modal-header img{
    width: 25px;
}
.addpayment_modalmandiv .modal-header .close{
    margin-top: 0;
    opacity: 10;
    text-shadow: none;
}
.addpayment_modalmandiv .modal-header {
    padding: 18px;
    border-bottom: 1px solid #B9B9B9;
    padding-top: 25px;
    padding-bottom: 25px;
}
.addpayment_modalmandiv .modal-body{
    padding: 18px;
    padding-top: 25px;
    padding-bottom: 25px;
}
.card_typeinputbox{
    display: inline-block;
    width: 100%;
    margin-bottom: 36px;
}
.card_typeinputbox p{
    color: #3C4257;
    font-size: 16px;
    margin-bottom: 4px;
}
.card_typeinputbox select{}

.card_typeinputbox .custom-select {
  position: relative;
}

.card_typeinputbox .custom-select select {
  display: none; 
}
.card_typeinputbox .select-selected {
    border-bottom: 1px solid #E1E1E1 !important;
}
.card_typeinputbox .select-selected:after {
    position: absolute;
    content: "";
    top: 15px;
    right: 10px;
    /* background: url(../svg/select-aroow.svg) top right no-repeat; */
    background-size: cover;
    width: 20px;
    height: 20px;
}
.card_typeinputbox .select-items div,.card_typeinputbox .select-selected {
    color: #000;
    padding: 8px 16px;
    border: 1px solid #E1E1E1;
    cursor: pointer;
    user-select: none;
    font-size: 17px;
    background: #fff;
    border-bottom: 0px;
    /* background: #fff url(../svg/Credit-Card-icon.svg) top right no-repeat; */
    background-size: 30px;
    left: 0;
    background-position: 14px;
    padding-left: 56px;
    height: 52px;
    padding-top: 13px;
}
.btnLoadMore , .btnLoadMore:active , .btnLoadMore:focus , .btnLoadMore:hover  {
    background-color: #00824F;
    border: 0;
    box-shadow:unset !important;
}
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}
.select-hide {
  display: none;
}
.card_typeinputbox input{
    border: 1px solid #E1E1E1;
    height: 52px;
    padding: 13px;
    width: 100%;
    color: #000000;
    font-size: 17px;
    padding-left: 56px;
}
#updatePaymentModel .card_typeinputbox{
    margin-bottom: 20px;
}
.card_typeinputbox img{
    width: 32px;
    margin-top: -71px;
    margin-left: 16px;
}
.savepayment_divbox{
    display: inline-block;
    width: 100%;
}
.savepayment_divbox a{
    background: #009C67;
    width: 100%;
    height: 55px;
    border-radius: 4px;
    color: #fff !important;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 54px;
}
.addpayment_modalmandiv .modal-footer {
    padding: 18px;
    text-align: right;
    border-top: 9px solid #DEDEDE;
    margin-top: 16px;
    padding-top: 26px;
    padding-bottom: 11px;
}
.secured_paytextbox{
    display: inline-block;
    width: 100%;
}
.secured_paytextbox p{
    margin: 0px;
    color: #9F9F9F;
    font-size: 13px;
    text-align: left;
}
.Stripeicon_div{
    margin-top: -28px;
    width: 130px;
    float: right;
}
#updateHomeCareModel .modal-dialog{
    width: 960px;
    margin: 30px auto;
}

.coverdivsubscribe{
    display: inline-block;
    width: 100%;
    margin-top: 20px;
}
.coverdivsubscribe .createaccount_btn a{
    width: 230px;
}
.coverdivsubscribe .dayfree_text h3{
    font-size: 36px;
    max-width: 551px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.cancelsubscritpion{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
.cancelsubscritpion a{
    display: inline-block;
    background-color: #00824f;
    color: #fff !important;
    padding: 10px 40px;
    font-size: 17px;
    border-radius: 8px;
    cursor: pointer;
}
/* END PAYMENT MODEL */


.nodatafoundref{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
   
}
.nodatafoundref h6{
    font-size: 20px;
    color: #000;
    margin-top: 0px;
    margin-bottom: 0px;
}

.nodatafoundref img{
    height: 80px;
    color: #000;   
}


.nodatafound{
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 70px;
}
.nodatafound h6{
    font-size: 30px;
    color: #000;
    margin-top: 14px;
}

.setbtncls{
    border: 1px solid;
    padding: 10px;
    border-radius: 9px;
    cursor: pointer;
    color: #000;
}


/* Shimmer Effect */
@-webkit-keyframes blinker { 
    from {opacity: 1.0;}
  to {opacity: 0.0;} 
}

@-moz-keyframes blinker { 
    from {opacity: 1.0;}
  to {opacity: 0.0;} 
}

@keyframes blinker { 
    from {opacity: 1.0;}
  to {opacity: 0.0;} 
}
  
.shimmerEffect {
   background-image: #E0E0E0 !important;
   background: #E0E0E0 !important;
   background-size:0;
  position:relative;
  overflow:hidden;
    text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:ease-in-out;
  -webkit-animation-direction: alternate;
  display: block;
}
.shimmerEffect::before {  
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: calc(200% + 200px);
    bottom: 0;
    background-image: inherit;
    animation: move 1s linear infinite;
    z-index: 1;
    background: #E0E0E0;
}

/* End Shimmer Effect */

.modal.show .modal-dialog{
    margin-bottom: 120px;
}
.Toastify{
    z-index: 11111;
    position: relative;
}
.select_province_tag{
    display: inline-block;
    width: 100%;
    /*margin-top: 24px;*/
}
.select_province_tag select{
    -webkit-appearance: listbox !important;
    border-radius: 0;
    height: 54px;
    border: 1px solid #fff !important;
    box-shadow: 0 0 5px #c4c4c4;
/*    box-shadow: 5px 7px 0px rgb(217 217 217);*/
    cursor: pointer;
    padding: 10px;
    width: 100% !important;
    font-size: 16px;
    color: #000;
}   

.company_footr{
    display: inline-block;
    /* width: 100%; */
    text-align: right;
    margin-top: 15px;
    float: right;
    position: absolute;
    right: 0;
}
.company_footr p{
    font-size: 15px;
    color: #000;
    margin: 0px;
}
.company_footr p a{
    color: #00824F;
}
.resetpassword_div{
    margin: 0px;
    font-size: 16px;
    color: #636161;
    float: right;
}

.resetpassword_div a {
    color: #00824F;
    font-family: 'proximanova-bold';
    text-decoration: underline !important;
}
.dlLinkText{
    display: inline-block;
    width: 100%;
}

.dlLinkText label{
    float: left;
}
.dlLinkText a{
    display: inline-block;
    float: left;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px;
    color: #00824f;
}
.newbtn_set{
    float: left;
    width: 50%;
    background-color: #01814E;
    padding: 14px 13px;
}
.newbtn_set1{
    background-color: #01B971;
}
.newbtn_set h4{
    color: #fff;
    margin: 0px;
    font-size: 16px;
    font-family: 'ProximaNova-Regular';
}
.newbtn_set p{
    color: #fff;
    margin: 0px; 
    font-size: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'ProximaNova-Regular';
}

.mg-t170{
    margin-top: 100px;
}

.makenew_design{
    float: left;
    width: 50%;
    background-color: #01814E;
}
.add_btn_user{
    background: #00824F;
    font-size: 15px;
    color: #fff !important;
    padding: 8px 29px;
    border-radius: 20px;
    display: inline-block;
}

.userlist_mandiv tbody tr td{
    font-size: 14px;
    color: #5a5a5a;
}
.userlist_mandiv tbody tr td a img{
    width: 22px;
    height: 25px;
    object-fit: contain;
    margin-right: 7px;
}
.userlisttop_mandiv{
    width: 100%;
}
.userlisttop_mandiv .userlist_mandiv{
    width: 100%;
}
.deletemodal_set .modal-dialog{
    width: 308px;
}



.sidebar-toggle {
    display: none;
}


.notMainDiv{
    margin-top: 15px;
    border:1px solid #00824F;
    padding:15px;
    border-radius:5px;
    position: relative;
    display: inline-block;
    width: 100%;
}
.notMainDiv span{
    position: absolute;
    left: 13px;
}
.notMainDiv p{
    padding-left: 44px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
 .match_boxmandiv .subdivdoc span{
    font-size: 12px;
    display: inline-block;
}
.match_boxmandiv .subdivdoc p{
    display: inline-block;
    font-size: 12px;
}


/*new add css*/


.refer_textbox{
    display: inline-block;
    width: 100%;   
    text-align: center;
    margin-bottom: 25px;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 25px;
}

.refer_textbox h3{
    margin: 0px;
    color: #00824F;
    font-size: 22px;
    margin-bottom: 12px;
}
.refer_textbox p{
    margin: 0px;
    font-size: 15px;
    color: #636161;
}
.price_coddiv{
    display: inline-block;
    width: 100%;
    margin-top: 0px;
}
.inner_pricebox{
    display: inline-block;
    width: 100%;
    border: 1px solid #00824f;
    border-radius: 5px;
    text-align: center;
}
.inner_pricebox p{
    margin: 0px;
    font-size: 15px;
    color: #fff;
    background-color: #00824f;
    padding: 7px 0px;
}
.inner_pricebox h3{
    margin: 0px;
    font-size: 22px;
    color: #000;
    padding: 28px 0px;
}
.referral_linkbox{
    display: inline-block;
    width: 100%;
    border: 1px solid #AEAEAE;
    padding: 21px 16px;
    margin-top: 25px;
    border-radius: 5px;
}
.referral_linkbox h3{
   margin: 0px;
    color: #00824F;
    font-size: 22px;
    margin-bottom: 12px;
    text-align: center;
}
.referral_linkbox p{
    margin: 0px;
    color: #636161;
    font-size: 15px;
    margin-bottom: 24px;
    line-height: 19px;
    text-align: center;
}
.referral_linkbox a{
    display: inline-block;
    color: #000;
    font-size: 16px;
    border: 1px solid #AEAEAE;
    border-radius: 5px;
    padding: 18px 27px;
    background: #F1F1F1;
    line-break: anywhere;
    min-width: 470px;
    margin-bottom: 8px;
}
.referral_history{
    display: inline-block;
    width: 100%;
}
.referral_history{
    display: inline-block;
    width: 100%;
    border-top: 1px solid #DEDEDE;    
    margin-top: 35px;
    padding-top: 31px;
}
.referral_history h3{
    margin: 0px;
    color: #00824F;
    font-size: 22px;
    margin-bottom: 12px;
    text-align: center;
}
.referral_history ul{
    padding: 0px;
    margin: 0px;
    
}
.scrollbar_boxdiv ul li{
    border-bottom: 1px solid #DEDEDE;
}
.borderBottumSet{
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 25px;
    margin-bottom: 25px !important;
}

.scrollbar_boxdiv{
    display: inline-block;
    width: 100%;
    height: 125px;
    overflow: auto;
}
.scrollbar_boxdiv::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrollbar_boxdiv::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.scrollbar_boxdiv::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.scrollbar_boxdiv::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.cmnwidthamount{
    width: 20%;
   float: right;
   margin-bottom: 5px;
}

.cmnwidthref{
   width: 35%;
   float: left;
   margin-bottom: 5px;
}
.cmnwidthmsg{
   width: 45%;
   float: left;
   margin-bottom: 5px;
}

/*
.referral_history ul li{    
    width: 33.33%;
    float: left;
    margin-bottom: 5px;
}*/
.referral_history ul li span{
    color: #252526;
    font-size: 15px;
}
/*new add css*/

/*check box css*/
.custam_checkdiv{
    display: inline-block;
    width: 100%;
    margin-top: 40px;
}
.custam_checkdiv h3{
    margin: 0px;
    color: #00824F;
    font-size: 22px;
    margin-bottom: 16px;
}
.custam_checkdiv .checkcontainer {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 21px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    user-select: none;
    width: 24%;
    color: #000;
    font-family: 'ProximaNova-Regular';
}

/* Hide the browser's default checkbox */
.custam_checkdiv .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custam_checkdiv .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.custam_checkdiv .checkcontainer input:checked ~ .checkmark {
  background-color: #00824f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custam_checkdiv .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custam_checkdiv .checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custam_checkdiv .checkcontainer .checkmark:after {
      left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
/*check box css*/
.checksubmitbtn{
    background: #00824F;
    font-size: 16px;
    color: #fff;
    padding: 10px 57px;
    border-radius: 6px;
    border: 0px;
    margin-top: 19px;
}

.chatbox_main{
    display: block;
    width: 70%;
    padding: 25px 21px;
    box-shadow: 0px 0px 5px rgb(196 196 196);
}
.response_message{
    display: inline-block;
    width: 100%;
    margin-bottom: 29px;
    height: 380px;
    overflow: auto;
}
.response_message p{
    color: #636161;
    margin: 0px;
    font-size: 16px;
}
.chatbox_main textarea{
    width: 77%;
    height: 60px;
    padding: 9px 15px;
    border: 1px solid #d5d5d5;
    resize: none;
    font-size: 18px;
}
.chatbox_main .custome_chat_btn{
    background: #00824F;
    font-size: 20px;
    color: #fff;
    padding: 16px 49px;
    border-radius: 50px;
    text-align: center;
    /* margin: 0 auto; */
    /* display: inherit; */
    margin-top: 0px;
    float: right;
    border: 0;
}

/* Vehicle Lead */
.carslider_manboxdiv{
    display: inline-block;
    width: 100%;
    margin-top: 20px;
}
.sliderinner_box{
    display: inline-block;
    width: 100%;
    /* box-shadow: 5px 7px 0px rgb(217 217 217); */
    border: 1px solid #d8d8d8;
    padding: 10px 10px;
    margin-bottom: 30px;
    position: relative;
}
.sliderinner_box>div{
    width: 100% !important;
    background-color: transparent !important;
}
.sliderinner_box img{   
    width: 27px !important;
    height: 27px;
    object-fit: cover;
    position: inherit;
    left: 0;
    right: 0;
    top: 0;
    background-color: #000;
}
.cartext_details{
    display: inline-block;
    width: 100%;
    margin-top: 20px;
}
.cartext_details h3{
    margin: 0px;
    color: #000;
    font-size: 20px;
    margin-bottom: 6px;
}
.cartext_details p{
    margin: 0px;
    color: #000;
    font-family: 'proximanova-bold';
    font-size: 16px;
}
.cartext_details p span{
    color: #787878;
}
.preview_btndiv{
    display: flex;    
    text-align: center;
    justify-content: space-around;
    margin-top: 7px;
}
.preview_btndiv a{
    display: inline-block;
    padding: 6px 0px;
    width: 156px;
    border-radius: 35px;
    border: 1px solid #00824F;
    color: #000;
    font-size: 12px;
}
.pickup_inputbox{
    display: inline-block;
    width: 100%;
    margin-top: 9px;
}
.pickup_inputbox h4{
    margin: 0px;
    color: #000;
    font-size: 15px;
    margin-bottom: 10px;
}
.pickup_inputbox h4 span{
    color: #787878;
}
.pickup_inputbox input{
    width: 58%;
    border: 1px solid #D1D1D1;
    padding: 6px 9px;
    border-radius: 6px;
    color: #000;
    font-size: 16px;
}
.pickup_inputbox a{
    background: #02824F;
    color: #fff;
    padding: 8px 26px;
    display: inline-block;
    font-size: 16px;
    border-radius: 26px;
    float: right;
}
/* .timeleft_text{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
} */
.timeleft_text h4{
    margin: 0px;
    color: #000;
    font-size: 15px;    
}
.timeleft_text h4 span{
    color: #787878;
}
.bid_modal .modal-dialog{
    width: 660px;
    max-width: 100%;
}
.bid_modal .history_detilsbox ul li{
    width: 198px;
}
.bid_modal .modal-body{
    height: 350px;
    overflow: auto;
}
/* Vehicle Lead */
/* .vehical_listmandiv .col-lg-6{
    width: 450px;
} */
/* .detail_modal{} */
.detail_modal .modal-dialog {
    margin-bottom: 120px;
    max-width: 890px;   
}
.leadimagediv{
    
}
.leadimagediv img{
    width: 100% !important;
    height: 220px;
    margin-bottom: 30px;
}
.leadimagediv span{
    display: inline-block;
    width: 100%;
}
.sliderparent :nth-child(1){
    width: 100%;
}
.sliderparent{
    position: relative;
    margin-bottom: 20px;
}
.sliderparent div{    
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;    
}
.openapi_main{
    width: 100%;
    box-shadow: none;
    padding: 0px;
}
.openapi_main textarea{
    width: 100%;
    padding-right: 222px;
}
.openapi_main.chatbox_main .custome_chat_btn{
    padding: 9px 49px;
    border-radius: 5px;
    margin-top: -62px;
    z-index: 1;
    position: relative;
    right: 7px;
}
.headtxt{
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #cbcbcb;
    padding: 21px 15px;
}
.headtxt span{
    font-size: 15px;
    color: #000;
}
.headtxt span strong{
    font-family: 'proximanova-bold';
}
.headtxt img{
    width: 142px;
    float: right;
}
.openai_mandivbox{
    display: inline-block;
    width: 100%;
    border: 1px solid #D5D5D5;
    margin-bottom: 20px;
    background-color: #fff;
}
.askhopper_btndiv{
    position: relative;
}
.askhopper_btndiv span{
    position: absolute;
    top: 11px;
    left: 17px;
}
.askhopper_btndiv textarea{
    width: 100%;
    height: 60px;
    padding: 13px 15px;
    border: 1px solid #d5d5d5;
    resize: none;
    font-size: 18px;
    border: 1px solid #D5D5D5;
    padding-left: 51px;
    padding-right: 222px;
}
.askhopper_btndiv .custome_chat_btn {
    background: #00824F;
    font-size: 20px;
    color: #fff;
    padding: 9px 49px;
    border-radius: 5px;
    margin-top: -62px;
    z-index: 1;
    position: relative;
    right: 7px;
    float: right;
    border: 0;
}
.openapi_main .response_message{
    margin-bottom: 0;
    float: left;
}
#tidio-chat-iframe{
    left: 0 !important;
}
.openai_topmandiv{
    background-color: #F1F1F1;
    padding-bottom: 30px;
    display: inline-block;
}
.openai_topmandiv .rightsidecontent{
    padding-left: 279px;
    
}
.ai_header{
    border-bottom: 1px solid #cbcbcb;
}
.aifooter{    
    text-align: center;
    margin-top: 30px; 
    display: inline-block;
    width: 100%;   
    padding-left: 250px;
}
.aifooter p{
    margin: 0px;
    color: #000;
    font-size: 16px;
}
.setpending{
    float: left;
    width: 20%;
    padding: 0px 5px;
}
.setpending .sliderinner_box{
    border: 1px solid #00824f;    
    background-color: #fff;
    border-radius: 8px;
}
.colSetContent span{
    float: left;
    font-size: 14px;
    color: #000;
}
.colSetContent p{
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #787878;
}


.skip_txt{
    cursor: pointer;
   color: #00824F;
   margin-top: 23px;
}


/* Drage Drop  */
.subdivdoc{
   border: 1px solid #000;
   padding: 9px 8px;
   /* margin-bottom: 14px; */
   width: 49%;
   float: left;
   margin-right: 3px;
}
.subdivdoc img{
   width: 26px;
   margin-right: 3px;
}
.maindivdoc{
   display: inline-block;
   width: 100%;
   margin-top: 18px;
}
.inner_matchtext .maindivdoc p{
   color: #000;
   margin-bottom: 7px;
}
.DragDropContextDiv{
   min-height: 100vh;
   width: 100%;
   color: #000;
   padding-bottom: 2rem;
   display: inline-block;
}

.dropebale{
    min-height: 100vh;    
}
.collumndiv{    
    /* border: 1px solid #c3c3c3; */
    width: 20%;
    float: left;
    padding: 1px;
    min-height: 780px;
    padding-top: 11px;
}
.colSetContent{
   background-color: #fff;
   border: 1px solid #fff;
   border-radius: 0px;
   padding: 17px 11px;
   margin: 11px 5px;
   position: relative;
   box-shadow: 0 0 5px #c4c4c4;
}
.setcolumnTitle{
   background-color: #00824f;
   color: #fff;
   padding: 3px 5px;
   border-radius: 7px;
   text-align: center;
   width: 95%;
   /* display: inline-block; */
   margin: 0 auto;
}
.setcolumnTitle span{
   font-size: 16px;
}
.removeiconsetdrag{
    position: absolute;
    top: 0px;
    right: 7px;
}
.removeiconsetdrag button{
    border: 0;
    background-color: transparent;
}
.marketplace_search .removeiconsetdrag img{
    margin: 0;
    width: 12px;
}

.response_que{
    display: inline-block;
    width: 100%;
    padding: 28px 21px;
    border-bottom: 1px solid #dedede;
    padding-left: 100px;
}
.response_ans{
    display: inline-block;
    width: 100%;
    padding: 28px 21px;
    background-color: #efefef;
    border-bottom: 1px solid #dedede;
    padding-left: 100px;   
}
.response_message .response_text{
    padding: 14px 21px;
    color: #fff;
    font-size: 22px;
    background-color: #00824f;
}
.openaiprofile{
    position: relative;
    display: inline-block;
    width: 100%;
}
.openaiprofile img{
    position: absolute;
    left: 28px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    top: 17px;
}
.choosefile_label {
    background-color: #00824f;
    padding: 9px 20px;
    color: #fff !important;
    font-size: 15px;
    font-weight: normal !important;
    border-radius: 30px;
    display: table;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 18px;
}
.choosefile_label input[type=file] {
    display: none;
}
.loader {
    display: none;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* Drage Drop */
/* .scrollmaindiv{
    overflow: auto;
    height: 93vh;
}

.scrollmaindiv::-webkit-scrollbar {
    width: 3px;
  }

  .scrollmaindiv::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }

  .scrollmaindiv::-webkit-scrollbar-thumb {
    background: #888; 
  } */


.customelogo{
    display: inline-block;
    width: 100%;
    position: relative;
}
.customelogo [type=file] {
    height: 0;
    overflow: hidden;
    width: 0;
}
.customelogo [type=file] + label {
    border-radius: 17px;
    color: #000;
    cursor: pointer;
    display: inline-block;    
    padding: 23px 0px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    border: 3px dotted #AEAEAE;
    width: 100%;
    text-align: center;
    font-size: 20px;
}
.customelogo .greentik{
    position: absolute;
    right: 30px;
    width: 25px;
    top: 27px;
}
.setselltrade_box h3{
    text-align: left;
    color: #000;
}
.setselltrade_box p{
    text-align: left;
    color: #000;
}
.task_borderdiv{
    display: inline-block;
    width: 100%;
    border: 1px solid #C9C1C1;
    padding: 45px 55px;
    margin-bottom: 30px;
}
.task_borderdiv h2{
    margin-bottom: 18px;
    color: #000;
    font-family: 'ProximaNova-Regular';
    font-size: 28px;
}
.addtask_btndiv{
    display: inline-block;
    width: 100%;
    text-align: right;
    border-bottom: 1px solid #C9C1C1;
    padding-bottom: 27px;
}
.addtask_btndiv button{
    padding: 13px 70px;
    color: #fff;
    font-size: 20px;
}
.task_borderdiv .col-md-12{
    margin-bottom: 22px;
}
.task_borderdiv label{
    color: #000;
    font-size: 18px;
    margin-bottom: 5px;
}
.task_borderdiv input{
    padding: 14px 10px;
    border: 1px solid #E5E6E8;
    border-radius: 7px;
    font-size: 17px;
    color: #000;
}
.task_borderdiv select{
    padding: 14px 10px;
    border: 1px solid #E5E6E8;
    border-radius: 7px;
    font-size: 17px;
    color: #000;
}
.pendingtask_mandiv{
    display: inline-block;
    width: 100%;
    padding-top: 25px;
}
.pendingtask_mandiv .custometable{
    min-width: 100%;
}
.pendingtask_mandiv h5{
    margin-bottom: 10px;
    color: #000;
    font-size: 27px;
}
.pendingtask_mandiv table tbody tr td{
    font-size: 17px;
    color: #000;
}
.pendingtask_mandiv .actionicon button{
    box-shadow: none;
    border: 0;
    background-color: transparent;
}
.main_detail_div{
    display: inline-block;
    width: 100%;
}
.leaddetail_coverdiv{
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
}
.leaddetail_coverdiv .inner_matchtext h3{
    margin-bottom: 15px;
    color: #000;
    font-size: 30px;
    padding-left: 90px;
}
.leaddetail_coverdiv .inner_matchtext img{
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
}
.leaddetail_coverdiv .inner_matchtext p{
    padding-left: 90px;
    font-size: 16px;
}
.leaddetail_coverdiv .inner_matchtext span{
    font-size: 16px;
}
.cirtificate_cover_div{
    display: inline-block;
    width: 100%;
    border: 1px solid #1DA670;
    border-radius: 3px;
    padding: 15px 12px;
}
.cirtificate_cover_div h3{
    margin-bottom: 1px;
    color: #000;
    font-size: 21px;
}
.cirtificate_cover_div a{
    color: #000;
    font-size: 15px;
    line-break: anywhere;
    line-height: normal;
    font-family: 'ProximaNova-Regular';
}

.hidden{
display: none;
}
.visible{
    display: block;
}


/*start subscribecss*/
.dayfree_text{
    display: inline-block;
    width: 100%;
    text-align: center;
}
.dayfree_text h3{
    color: #00824F;
    font-size: 30px;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
}
.dayfree_text p{
    margin: 0px;
    color: #000;
    font-size: 32px;
    display: inline-block;
    border: 2px solid #00824F;
    padding: 7px 42px;
    display: inline-block;
    border-radius: 6px;
}
.dayfree_text p span{
    font-size: 18px;
}
/*End subscribecss*/




















@media (max-width: 1200px) {
    .aifooter {
        text-align: center;
        margin-top: 19px;
        display: inline-block;
        width: 100%;
        padding-left: 25px;
    }
    .openai_topmandiv .rightsidecontent {
        padding-left: 25px;
    }
    .account_textbox {        
        margin-bottom: 30px;
    }   
    .ask_btndiv {        
        position: initial;       
    }
    .inner_pricebox{
        margin-top: 8px;
    }
    .availablebalance_text{
        padding-top: 56px;
    }
    .newbtn_set p{
        width: 134px;
    }
    .newbtn_set p {    
        font-size: 12px;            
        margin-right: 4px;
    }
    .newbtn_set p span {    
        width: 42px;
    }
    .marketplace_boxdiv {    
        margin-top: 31px;
    }
    header {    
        position: inherit;
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #D1D1D1;
        padding-left: 0;
        float: left;
    }
    .top_headerdiv {    
        padding-left: 50px;
    }
    .top_logodiv img {
        width: 170px;
    }
    .header_righttext {    
        width: 239px;    
    }
    .header_balancetext a {    
        font-size: 13px;    
        padding: 9px 15px;    
        margin-top: -7px;
    }
    .header_balancetext p {    
        font-size: 15px;
    }
    .header_lefttext li h3 {    
        font-size: 15px;
    }
    .top_logodiv {    
        margin-right: 15px;
        margin-top: 6px;    
    }
    .vertical_logobox {    
        width: 390px;    
        position: relative;
        height: auto;
    }
    .sidebarmenu{
        top: 0px;
        width: 100%;
    }
    .rightsidecontent {    
        padding-left: 25px;
        padding-right: 25px;    
        padding-top: 30px;
    }
    .sidebar-toggle.is-closed .menuiconcloseset {
        display: none !important;
    }
    .sidebar-toggle {
        top: 16px;
        left: 3px;
        position: fixed;
        z-index: 1111;
        border: 0;
        outline: none;
        width: 45px;
        height: 45px;
        border-radius: 0;
        margin-left: 0;
        margin-top: 0;
        cursor: pointer;
        transition: .5s;
        margin-top: 0;
        background: transparent;
    }
    .sidebar-toggle {
        display: inline-block;
    }
    .sidebar-toggle.is-closed {
        -webkit-transform: translateX(0em) rotate(0deg);
        transform: translateX(0em) rotate(0deg);
    }
    .sidebar-toggle.is-closed .menuiconset {
        display: inline-block !important;
    }
    .sidebar-toggle img {
        width: 33px;
    }
    .menuiconset {
        display: none !important;
    }
    .menuiconcloseset {
        display: inline-block !important;
    }
    .sidebar-toggle img {
        width: 33px;
    }
    .sidebar {        
        height: 100vh;        
        transition: .7s;
        padding-bottom: 20px;
        overflow: scroll;
    }
    .sidebar.is-closed {
        -webkit-transform: translateX(-77.7em);
        transform: translateX(-77.7em);
    }
}



@media (max-width: 991px) {
    .top_logodiv {
        margin-right: 0px;
        margin-top: 0px;
    }
    .sidebar-toggle{
        top: 9px;
    }
    .chatbox_main {       
        width: 100%;
        padding: 16px 17px;        
    }
.link_a_btn{
    margin-top: 15px;
    position: inherit;
    width: 100%;
}
.inner_pricebox{
        margin-top: 8px;
    }
    .timeleft_text {                
        position: relative;
        bottom: 0;
        margin-top: 16px;
    }
    .newbtn_set p {
        width: 166px;
    }
    .newbtn_set p span {
        width: 80px;
    }
    .select_province_tag{    
        margin-top: 24px;
        /* margin-bottom: 20px; */
    }   

    .match_boxmandiv{
        min-height: auto;
        margin-bottom: 30px;
    }
    .email_inputbox {
        width: 100%;
    }
    .match_boxmandiv a {    
        position: initial;    
        width: 100%;
    }
    .customer_btndiv {    
        margin-bottom: 30px;
    }
    .contentCoverbox{
        padding-bottom: 0px;
    }
    .footer_logodiv{
        position: inherit;
    }
    .customer_btndiv .checkcontainer {    
        float: left;
        margin-left: 0;
        margin-top: 12px;
    }
    .customer_btndiv a {
        display: flex;    
        margin-left: 0;
        justify-content: center;
        margin-bottom: 10px;
    }
    .header_righttext {
        width: auto;
        margin-top: 10px;
    }
    .header_righttext .header_balancetext p{
        display: none;
    }
    .header_lefttext {    
        display: none;
    }
    .myaccount_mandiv {    
        display: inline-block;
        width: 100%;
    }
    .transactionhistory_mandiv {    
        width: 100%;
    }
    .userlist_mandiv {    
        width: 100%;
    }
    .account_categorybox ul li a {    
        width: 100%;
        padding: 8px 12px;
        font-size: 15px;
    }
    .account_categorybox ul li {
        margin-right: 0;
        width: 100%;
        margin-bottom: 11px;
    }
    .account_categorybox ul {    
        text-align: center;
    }   
    .create_detailsbox {    
        width: 98%;
    }
    .vertical_logobox {    
        display: none;
    }
   .marketplace_boxdiv .col-lg-3 {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .response_que{
        padding-left: 85px;
    }
    .response_ans {    
        padding-left: 85px;
    }
    .collumndiv {
        width: 50%;        
    }
    .headtxt img {        
        float: left;        
    }
    .headtxt span {        
        display: inline-block;
        width: 100%;
    }
    .askhopper_btndiv .custome_chat_btn {        
        padding: 9px 30px;        
        right: 0;
        float: left;
        border: 0;
        margin-top: 0px;
    }
    .askhopper_btndiv textarea {        
        height: 60px;
        padding-right: 15px;
    }
    .bid_modal .history_detilsbox ul li {
        width: 33.33%;
    }
    .bid_modal .modal-dialog {
        width: 97%;        
    }
    .chatbox_main .custome_chat_btn {        
        padding: 10px 43px;       
        margin: 0 auto;
        display: inherit;        
        float: none;        
    }
    .chatbox_main textarea {
       width: 100%;       
    }
    .response_message {       
        height: 135px;
        overflow: auto;
    }
    .custam_checkdiv .checkcontainer {  
        font-size: 16px;  
        width: 50%;
    }
    .inner_pricebox{
        margin-top: 8px;
    }
    .cmnwidthref{
     width: 35%;
      }
    .cmnwidthmsg{
       width: 45%;     
   }
    .cmnwidthamount{
        width: 20%;
    }
    .referral_history ul li span {
    font-size: 14px;
 }


      .referral_linkbox a{
        min-width: 380px;
        padding: 11px 13px;
        font-size: 15px;
    }
    
    .newfootlogo {    
        position: relative;
        right: 0;
        top: 0;    
        display: flex;
        margin: 0 auto;    
        margin-top: 12px;
    }
    .company_footr {
        text-align: center;
        margin-top: 6px;
        width: 100%;
        position: inherit;
    }
    .notescustomer_boxdiv {        
        width: 100%;
    }
    .secured_paytextbox p{
        font-size: 13px;
    }

    .secured_paytextbox p {
        text-align: center;
    }
    .Stripeicon_div {
        float: none;
        display: block;
        margin: 0 auto;
        margin-top: 6px;
    }

    
    /*.account_inputbox input {
        width: 100%;    
    }*/
    .create_innerboxdiv {    
        padding: 20px 12px;
    }
    .balance_textbox ul li a {    
        font-size: 16px;    
        width: 95%;
    }   
    .createaccount_btn {    
        margin-top: 8px;
    }
    .createaccount_btn a {    
        font-size: 16px;            
    }
    .accountbalance_textbox {    
        margin-top: -15px;
    }
    .create_innerboxdiv h2 {
        margin-bottom: 22px;
    }   
    .customer_btndiv {    
        margin-top: 5px;
    }
    .footer_logodiv {    
        margin-top: 50px;
    }
}

@media (max-width: 479px) {
    .dropebale {
        min-height: auto;
    }
    .collumndiv {
        width: 100%;
        min-height: auto;
    }
    .subdivdoc img {
        width: 34px !important;
        margin-right: 6px !important;
    }
    .match_boxmandiv .subdivdoc span {
        font-size: 15px;
    }
    .match_boxmandiv .subdivdoc p {
        font-size: 15px;
    }
    .subdivdoc {        
        width: 100% !important;        
        margin-right: 0 !important;
        margin-bottom: 10px;
    }
     .inner_pricebox{
        margin-top: 8px;
     }
     .cmnwidthref{
     width: 40%;
      }
    .cmnwidthmsg{
       width: 50%;     
   }
    .cmnwidthamount{
        width: 10%;
    }
 .referral_history ul li span {
    font-size: 14px;
 }


    .userlisttop_mandiv table{
        overflow: auto;
        display: inherit;
    }
    .top_logodiv img {
        width: 158px;
    }
    .history_detilsbox ul li {    
        width: 33%;
        margin-bottom: 5px;
    }

    .referral_linkbox a{
        min-width: 50px;
    }
}
@media (max-width: 360px) {

     .inner_pricebox{
        margin-top: 8px;
     }
     .cmnwidthref{
     width: 40%;
      }
    .cmnwidthmsg{
       width: 50%;     
   }
    .cmnwidthamount{
        width: 10%;
    }
    .referral_history ul li span {
    font-size: 10px;
 }

    .newbtn_set p {
        width: 135px;
    }
    .newbtn_set p span {
        width: 57px;
    }

   .referral_linkbox a{
        min-width: 50px;
    }
}
/* media queries */









